import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import PlokerIndex from './pages/ploker/index'
import PlokerPlay from './pages/ploker/play'
import Index from './pages/index'
import NotFound from './pages/not-found'


function App() {
  useEffect(() => {
    // booting
  })

  let host = window.location.host
  let parts = host.split(".")
  let subdomain = ""
  if (parts.length > 2) subdomain = parts[0]

  if (subdomain === 'ploker') {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={PlokerIndex} />
          <Route path="/play" component={PlokerPlay} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    )
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
