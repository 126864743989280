
import React from 'react'

import '../../../styles/page.css'

function Page({ children }) {
  return (
    <div className="black-container container">
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>
        {children}
        </div>
      </div>
    </div>
  )
}

export default Page
