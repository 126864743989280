import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from 'react-redux'
import { setEstimate, setRoom, setName, setFlash } from '../../features/user/homeSlice'
import Page from '../../components/layouts/Ploker'
import { Paper, Stack, Button } from '@mui/material'
import { socketSend, addSocketListener } from '../../app/socket'

function Play() {
    const dispatch = useDispatch()
    const [getRedirectToLogin, setRedirectToLogin] = useState(false)
    const [canvas, setCanvas] = useState(false)
    const [prevShowCards, setPrevShowCards] = useState(false)
    const [currentDisplay, setCurrentDisplay] = useState(false)
    const user = useSelector((state) => state.home.user)
    const room = useSelector((state) => state.home.room)
    const flash = useSelector((state) => state.home.flash)

    function Vector(x,y){
        this.x = x
        this.y = y

        this.add = (vector) => {
            return new Vector(this.x + vector.x, this.y + vector.y)
        }

        this.subtract = (vector) => {
            return new Vector(this.x - vector.x, this.y - vector.y)
        }

        this.divide = (vector) => {
            return new Vector(this.x / vector.x, this.y / vector.y)
        }
    }

    function Canvas(){
        const _this = this
        this.canvas = document.getElementById('confetti-canvas')
        this.canvas.width  = window.innerWidth
        this.canvas.height = window.innerHeight
        this.center = new Vector(window.innerWidth / 2, window.innerHeight / 2)
        this.ctx = this.canvas.getContext("2d")
        this.gravity = new Vector(0,0.2)
        this.drag = new Vector(1.04,1.04)
        this.sprites = []
        this.spriteCount = 1000

        this.framerate = 80

        this.pop = () => {
            this.sprites = []
            for(let i=0; i<this.spriteCount;i++){ this.spawn()}
            this.tick()
            console.log('starting confetti')
        }

        this.rand = (min, max) => {
            return (Math.random() * (max - min)) + min
        }

        this.spawn = () => {
            this.sprites.push({
                pos: new Vector(this.rand(this.center.x-100,this.center.x+100), this.rand(this.center.y-100,this.center.y+100)),
                vel: new Vector(this.rand(-40,40), this.rand(-40,20)),
                size: this.rand(2,10),
                rotation: this.rand(0,180),
                zRotation: this.rand(0,45),
                color: '#' + Math.floor(Math.random()*16777215).toString(16)
            })
        }

        this.tick = () => {
            this.canvas.width  = window.innerWidth
            this.canvas.height = window.innerHeight
    
            this.sprites.forEach((sprite, index) => {
                // add drag
                sprite.vel = sprite.vel.divide(this.drag)
                // add gravity
                sprite.vel = sprite.vel.add(this.gravity)
                // add noise
                sprite.vel = sprite.vel.add( new Vector( this.rand(-0.2,0.2),0 ) )
                // add rotation
                sprite.rotation = sprite.rotation + this.rand(-4, 4)
                // sprite.zRotation = sprite.rotation + this.rand(-0.1,0.1)
                // tick
                sprite.pos = sprite.pos.add(sprite.vel)
                

                // cleanup
                if( sprite.pos.y > this.canvas.height + 5 ){
                    this.sprites.splice(index,1)
                    return
                }

                // render
                const width = sprite.size - ((sprite.size / 90) * (sprite.zRotation % 90))
                this.ctx.save()
                this.ctx.translate(sprite.pos.x, sprite.pos.y)
                this.ctx.rotate(sprite.rotation * Math.PI/180)
                this.ctx.fillStyle = sprite.color
                this.ctx.fillRect(0, 0, sprite.size, width)
                this.ctx.fill()
                this.ctx.restore()
            })

            if(this.sprites.length < 1) {
                this.ticking = false
                return console.log('finished confetti')
            }

            if(this.ticker) clearTimeout(this.ticker)

            this.ticker = setTimeout(() => {
                _this.tick()
            }, Math.round(1000 / this.framerate))
        }
    }


    // end of confetti

    const cardOptions = [
        '0', '0.5', '1', '2', '3', '5', '8', '13', '20', '?'
    ]

    useEffect(() => {

        if(canvas === false) {
            const canvas = new Canvas()
            setCanvas(canvas)
        }

        function returnedRoom(response){
            dispatch(setRoom(response.room))
            
            response.room.users.forEach((roomUser) => {
                if(roomUser.id === user.id){
                    dispatch(setEstimate(roomUser.estimate))
                }
            })
        }

        function validUrlRoom(){
            let slugs = window.location.pathname.split('/')
            let roomId = slugs[slugs.length -1]

            if (
                roomId &&
                !isNaN(roomId) &&
                roomId.length > 4 &&
                parseInt(roomId) !== parseInt(user.roomId)
            ) {
                return roomId
            }

            return false
        }

        const urlRoomId = validUrlRoom()
    
        if(urlRoomId){
            socketSend({ action: 'setRoom', roomId: urlRoomId })
        }

        addSocketListener(returnedRoom, 'returnRoom')

        // if we don't have a username - redirect to login
        if(!user.name) return setRedirectToLogin(true)
        
    }, [user])

    function esimate(index){
        dispatch(setEstimate(index))
        socketSend({ action: 'setEstimate', estimate: index })
    }

    function changeState(){
        if(room.showCards){
            socketSend({ action: 'updateRoom', reset: true })
        }
        else{
            socketSend({ action: 'updateRoom', showCards: true })
        }
    }

    function checkForWin(){
        // list the users and check the cards picked
        if(!room || !room.users) return
        let choices = room.users.map((roomUser) => { return roomUser.estimate }).filter(n => n)
        if(choices.length > 1 && choices.every( (val, i, arr) => val === arr[0] ) ) canvas.pop()
    }

    function logout(){
        Cookies.remove('user.name')
        dispatch(setName(null))
        setRedirectToLogin(true)
    }

    function onKeyPressed(e) {
        console.log(e.key);
      }

    if (getRedirectToLogin) {
        console.log('redirecting...')
        return <Redirect to='/' />
    }

    if(prevShowCards !== room.showCards){
        // if(canvas) canvas.pop()
        setPrevShowCards(room.showCards)
        if(room.showCards) checkForWin()
    }

    return (
        <Page onKeyDown={onKeyPressed}>
            { flash && <h2 className="flash">{flash}</h2> }
            <canvas id="confetti-canvas"/>
            <h1 className="player-name" onClick={logout} title="Change name">{user.name}</h1>

            <Button className="reset" onClick={changeState} variant="contained">{`${room.showCards ? 'Reset' : 'Show Cards'}`}</Button>

            <div className="player-holder">
                {
                    room.users && room.users.map((roomUser, index) => {
                        return(
                            <div className={`player ${room.showCards ? '' : 'hidden'}`} key={index}>
                                { roomUser.estimate !== null && <div className="card">
                                    {
                                        room.showCards && cardOptions[roomUser.estimate]
                                    }
                                    </div>
                                }
                                <div className={`name ${roomUser.id === user.id ? 'self' : ''}`}>{roomUser.name}</div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="deck-holder">
            <Stack
                direction='row'
                spacing={{ xs: 0, sm: 1, md: 2 }}
                justifyContent="center"
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                    width: 50,
                    height: 80,
                    borderRadius: 2,
                    overflow: 'hidden'
                    },
                }}
            >
                {
                    !room.showCards && cardOptions.map((option, index) => {
                        return (
                            <Paper
                                elevation={3}
                                key={index}
                                onClick={() => {esimate(index)}}
                            >
                                <div className={`card ${user.estimate === index ? 'highlighted' : ''}`}>{option}</div>
                            </Paper>
                        )
                    }) 
                }
            </Stack>
            </div>
        </Page>
    )
}

export default Play
