import React from 'react'
import Page from '../components/layouts/Page'

function NotFound() {
  return (
    <Page>
      <h1 className="text-center">Don't Play the fool.</h1>
    </Page>
  )
}

export default NotFound
