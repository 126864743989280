import React, { useEffect } from 'react'
import Page from '../components/layouts/Page'

// Index FOR jameswhite.webstie
function Home() {

    function Vector(x, y) {
        this.x = x
        this.y = y

        this.clone = () => {
            return new Vector(this.x, this.y)
        }

        this.add = (vector) => {
            return new Vector(this.x + vector.x, this.y + vector.y)
        }

        this.subtract = (vector) => {
            return new Vector(this.x - vector.x, this.y - vector.y)
        }

        // returns radians
        this.divide = (vector) => {
            return new Vector(this.x / vector.x, this.y / vector.y)
        }

        // returns rads
        this.getDirection = () => {
            return Math.atan2(this.y, this.x)
        }

        this.getScaledMagnitude = () => {
            return (this.x * this.x) + (this.y * this.y)
        }

        this.getMagnitude = () => {
            return Math.sqrt(this.getScaledMagnitude())
        }

        this.setMagnitude = (mag) => {
            const oldMag = this.getMagnitude()
            return new Vector(
                this.x * mag / oldMag,
                this.y * mag / oldMag
            )
        }

        this.toS = () => {
            return 'x: ' + this.x + ', y: ' + this.y
        }

        this.toO = () => {
            return { x: this.x, y: this.y }
        }

        this.equals = (vector) => {
            return this.x === vector.x && this.y === vector.y
        }
    }

    function rand(min, max) {
        return (Math.random() * (max - min)) + min
    }

    function Hawk(game) {
        const hawk = this
        this.game = game
        this.pos = new Vector(
            rand(this.game.view.width - 10, this.game.view.width + 400),
            rand(0, this.game.view.height)
        )
        this.dir = new Vector(rand(-100, -10), rand(-10, 10))
        this.speed = 1.9
        this.turningSpeed = .02
        this.minProximity = 10
        this.ready = false

        this.img = new Image() // 112, 51
        this.img.addEventListener('load', function () { hawk.ready = true; }, false)
        this.img.src = 'hawk.png'

        this.tick = () => {
            // move in direction
            this.pos = this.pos.add(this.dir)

            // turn to focus
            let newDir = this.game.focus.subtract(this.pos).setMagnitude(0.01)
            this.dir = this.dir.add(newDir).setMagnitude(this.speed)

            // turn to nearest bird
            const nearest = this.getNearestBird()
            newDir = nearest.pos.subtract(this.pos).setMagnitude(this.turningSpeed)
            this.dir = this.dir.add(newDir).setMagnitude(this.speed)

            this.render()
        }

        this.getNearestBird = () => {
            let nearest = this.game.birds[0]
            let nearestDistance = 1000000
            this.game.birds.forEach((bird) => {
                if (this.pos != bird.pos) {
                    const scaledDistance = this.pos.subtract(bird.pos).getScaledMagnitude()
                    if (scaledDistance < nearestDistance) {
                        nearest = bird
                        nearestDistance = scaledDistance
                    }
                }
            })

            return nearest
        }

        this.render = () => {
            if (!this.ready) return
            const hawkSize = { x: 28, y: 13 }
            this.game.ctx.save()
            this.game.ctx.translate(this.pos.x, this.pos.y)
            this.game.ctx.rotate(this.dir.getDirection() + 1.5707963267948966)
            this.game.ctx.drawImage(this.img, 0, 0, hawkSize.x, hawkSize.y)
            this.game.ctx.restore()
        }
    }

    function Bird(game) {
        const bird = this
        this.game = game
        this.pos = new Vector(
            rand(-this.game.focus.x, 0),
            rand(0, this.game.view.height)
        )
        this.dir = new Vector(rand(-100, 0), rand(-100, 0))
        this.speed = 3
        this.size = 2
        this.turningSpeed = 0.04
        this.minProximity = 10
        this.hawkProximity = 100

        this.dir = this.dir.setMagnitude(this.speed)

        this.img = new Image() // 112, 51
        this.img.addEventListener('load', function () { bird.ready = true; }, false)
        this.img.src = 'hawk.png'


        this.tick = () => {
            // move in direction
            this.pos = this.pos.add(this.dir)

            // turn to furthest bird
            // const neighbors = this.getBirdsByDistance()
            // const farthest = neighbors[neighbors.length-1].bird
            // let newDir = farthest.pos.subtract(this.pos).setMagnitude(this.turningSpeed)
            // this.dir = this.dir.add(newDir).setMagnitude(this.speed)

            // turn to focus
            let newDir = this.game.focus.subtract(this.pos).setMagnitude(this.turningSpeed)
            this.dir = this.dir.add(newDir).setMagnitude(this.speed)


            // turn away from hawk
            this.game.hawks.forEach((hawk) => {
                const hawkDistance = this.pos.subtract(hawk.pos).getScaledMagnitude()
                if (hawkDistance < this.hawkProximity * this.hawkProximity) {
                    newDir = hawk.pos.subtract(this.pos).setMagnitude(-1)
                    this.dir = this.dir.add(newDir).setMagnitude(this.speed)
                }
            })



            this.render()
        }

        // returns {scaledDistance: float, bird: Bird}
        this.getBirdsByDistance = () => {
            const birds = this.game.birds.map((bird) => {
                if (this.pos === bird.pos) return null
                const scaledDistance = this.pos.subtract(bird.pos).getScaledMagnitude()
                return {
                    scaledDistance: scaledDistance,
                    bird: bird
                }
            }).filter(n => n)

            birds.sort((a, b) => {
                if (a.scaledDistance < b.scaledDistance) return -1
                if (a.scaledDistance > b.scaledDistance) return 1
                return 0
            })

            return birds
        }

        this.render = () => {
            if (!this.ready) return
            this.game.ctx.beginPath()
            this.game.ctx.fillStyle = 'rgba(0,0,0,0.5)'
            this.game.ctx.fillRect(this.pos.x, this.pos.y, this.size, this.size)
            this.game.ctx.closePath()
        }
    }

    function Game() {
        const game = this
        this.ready = false
        this.process = document.getElementById('processing-canvas')
        this.process.width = window.innerWidth
        this.process.height = window.innerHeight
        this.ctx = this.process.getContext("2d")

        this.view = document.getElementById('view')
        this.view.width = window.innerWidth
        this.view.height = window.innerHeight
        this.viewCtx = this.view.getContext("2d")

        this.framerate = 50
        this.birdCount = 2000
        this.hawkcount = 3
        this.birds = []
        this.hawks = []
        this.focus = new Vector(window.innerWidth / 2, window.innerHeight / 2)
        this.embossOffset = 0
        this.maxEmbossOffset = 20

        this.bg = this.ctx.createLinearGradient(0, 0, 0, window.innerHeight)
        this.bg.addColorStop(0, "#82a8d9")
        this.bg.addColorStop(1, "#b1c8e6")

        this.init = () => {
            if (!this.ready) return this.renderEmboss()

            this.view.onmousedown = this.clicked
            this.view.onmousemove = this.mouseMoved
            this.resetCanvas()

            for (let i = 0; i < this.birdCount; i++) { this.birds.push(new Bird(game)) }
            for (let i = 0; i < this.hawkcount; i++) { this.hawks.push(new Hawk(game)) }
            this.tick()
        }

        this.clicked = (e) => {
            console.log('clicked')
            this.hawks.push(new Hawk(game))
            for (let i = 0; i < 10; i++) { this.birds.push(new Bird(game)) }
        }

        this.mouseMoved = (e) => {
            this.embossOffset += 0.2
            if (this.embossOffset > this.maxEmbossOffset) this.embossOffset = this.maxEmbossOffset

        }

        this.resetCanvas = () => {
            this.process.width = window.innerWidth
            this.process.height = window.innerHeight
        }

        this.renderEmboss = () => {

            // render emboss plate
            this.ctx.fillStyle = 'black'
            this.ctx.font = "bold 300px Helvetica"
            this.ctx.fillText("GO AWAY", ((this.view.width / 2) - 730), 500)

            this.ctx.globalCompositeOperation = "xor"
            this.ctx.fillRect(0, 0, this.view.width, this.view.height)

            this.ctx.shadowBlur = 6
            this.ctx.shadowOffsetX = this.ctx.shadowOffsetY = 2
            this.ctx.shadowColor = "#fff"
            this.ctx.drawImage(this.process, 0, 0)

            this.ctx.globalCompositeOperation = "source-over";
            this.ctx.shadowColor = "transparent";
            this.ctx.fillStyle = 'rgba(0,0,0,0.1)'
            this.ctx.fillText("GO AWAY", ((this.view.width / 2) - 730), 500)

            var imgData = this.process.toDataURL("image/png")

            this.emboss = new Image;
            this.emboss.onload = function () {
                game.ready = true
                game.init()
            }
            this.emboss.src = imgData
            return
        }

        this.overlay = () => {
            if (this.embossOffset > 0) {
                //add the offset bg
                game.viewCtx.fillStyle = game.bg
                game.viewCtx.fillRect(0, 0, game.view.width, game.view.height)
                game.viewCtx.fill()
                // add offset content
                game.viewCtx.drawImage(this.process, game.embossOffset, game.embossOffset)

                // // render the text with source destination-in
                game.viewCtx.font = "bold 300px Helvetica" // 680
                game.viewCtx.globalCompositeOperation = 'destination-in'
                game.viewCtx.fillText("GO AWAY", ((game.view.width / 2) - 730), 500)
                game.viewCtx.globalCompositeOperation = 'destination-over'

                // draw the content
                game.viewCtx.drawImage(this.process, 0, 0)
                // draw background
                game.viewCtx.fillStyle = game.bg
                game.viewCtx.fillRect(0, 0, game.view.width, game.view.height)
                game.viewCtx.fill()
                game.viewCtx.globalCompositeOperation = 'source-over'

                //add emboss
                game.viewCtx.globalAlpha = (this.embossOffset * 0.02)
                game.viewCtx.drawImage(game.emboss, 0, 0)
                game.viewCtx.globalAlpha = 1

                // reduce emboss
                this.embossOffset -= 0.2
            } else {
                // draw background
                game.viewCtx.fillStyle = game.bg
                game.viewCtx.fillRect(0, 0, game.view.width, game.view.height)
                game.viewCtx.fill()
                // draw the content
                game.viewCtx.drawImage(this.process, 0, 0)
            }


        }

        this.tick = () => {
            this.resetCanvas()
            this.birds.forEach((bird) => { bird.tick() })
            this.hawks.forEach((hawk) => { hawk.tick() })
            this.overlay()

            clearTimeout(window.ticker)
            window.ticker = setTimeout(() => {
                game.tick()
            }, Math.round(1000 / this.framerate))
        }

    }


    useEffect(() => {
        const game = new Game()
        game.init()
    })


    return (
        <Page>
            <canvas id="processing-canvas" />
            <canvas id="view" />
        </Page>
    )
}

export default Home
