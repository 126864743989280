import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const initialState = {
    user: {
        name: null,
        estimate: null,
        roomId: null
    },
    room: {
        roomId: null,
        users: []
    },
    flash: null
}


export const homeSlice = createSlice({
    name: 'home',

    initialState,

    reducers: {
        setupUser: (state, action) => {
            state.user = action.payload
            Cookies.set('user.name', action.payload.name, { expires: 90000 })
            Cookies.set('room.id', action.payload.roomId, { expires: 90000 })
        },
        setName: (state, action) => {
            state.user.name = action.payload
        },
        setEstimate: (state, action) => {
            state.user.estimate = action.payload
        },
        setRoom: (state, action) => {
            state.room = action.payload
        },
        setFlash: (state, action) => {
            console.log('a', action)
            state.flash = action.payload
        },
      resetUser: () => initialState,
    }
})

export const {
    setName,
    setupUser,
    setEstimate,
    resetUser,
    setRoom,
    setFlash
} = homeSlice.actions

export default homeSlice.reducer
