import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './app/store'

if (process.env.NODE_ENV === 'development') {
  document.title = '*Dev*'
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
