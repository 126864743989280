import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Cookies from 'js-cookie'
import Page from '../../components/layouts/Ploker'
import { useSelector, useDispatch } from 'react-redux'
import { setupUser } from '../../features/user/homeSlice'
import { TextField, Container, Button, Stack } from '@mui/material'
import { initSocket, socketSend, addSocketListener } from '../../app/socket'

function Home() {
    const [getPropsName, setPropsName] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const user = useSelector((state) => state.home.user)
    const roomId = useSelector((state) => state.home.user.roomId)

    useEffect(() => {
        const cookieName = Cookies.get('user.name')
        if(cookieName !== undefined){
            console.log('username cookie found')
            setPropsName(cookieName)
            submit()
        }
    })

    function validate_name(name){
        const normalised = name.toLowerCase().replace(/[^a-z]/gi, '')
        if(normalised.includes('stacy')){
            window.location.replace('https://c.tenor.com/_eF9uhdGbsYAAAAC/disney-pixar.gif')
            return false
        }
        return true
    }

    
    function handleChange(event) {
        const { value } = event.target
        if (value) {
            setPropsName(value.trim())
        }
    }

    function keyPress(e){
        if(e.keyCode === 13) submit()
     }

    function submit() {
        if(getPropsName.length < 3) return
        if(!validate_name(getPropsName)) return
        initSocket()
        socketSend({ action: 'setName', name: getPropsName })
        addSocketListener(returnedUser, 'returnUser')
        setLoading(true)
    }

    function returnedUser(response) {
        // add room id to cookie
        dispatch(setupUser(response.user))
    }

    if (roomId && user.name) {
        console.log('home redirecting to play', user.name)
        return <Redirect to={`/play/${roomId}`} />
    }
    
    return (
        <Page>
            { loading && <div className="loading" />}
            <h2 className="text-center">PLOKER</h2>
            <Container maxWidth="sm">
                <TextField
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    fullWidth
                    onChange={handleChange}
                    onKeyDown={keyPress}
                    inputProps={{style: {fontSize: 40}}}
                    value={getPropsName}
                    autoFocus
                />
                
                <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
                    <Button variant="contained" onClick={submit}>Go</Button>
                </Stack>
            </Container>
        </Page>
    )
}

export default Home
