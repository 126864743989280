// import { getToken } from './UserActions'

export function initSocket() {
    if(window.socket !== undefined) return
    console.log('initializing socket')

    const url = process.env.NODE_ENV === `development` ? 'ws://'+window.location.hostname+':4000/ws/' : 'wss://'+window.location.hostname+'/ws/'
    window.socket = new WebSocket(url)
    window.socketEvents = {}

    // send user token
    window.socket.onopen = function(){
        window.socket.addEventListener('message', messageReceived)
        // window.socket.send(JSON.stringify({action: 'authenticate', token: 'Bearer '+getToken()}) )
    }
    

    // boot to root if disconnected
    window.socket.onclose = function (e) { 
        alert('Disconnected from server')
        console.log(window.location.origin)
    }
}

export function socketSend(packet) {
    if(!window.socket || window.socket.readyState !== 1) {
        initSocket()
        setTimeout(function(){socketSend(packet)}, 1000)
        return
    }
    window.socket.send(JSON.stringify(packet))
    ping(true)
    if(window.debug) console.log('outbound', packet)
}

// keep ws alive, send ping to server every 45s
function ping(setting) {
    if(window.socket.pingTimer) clearTimeout(window.socket.pingTimer)
    window.socket.pingTimer = setTimeout(ping, 29000)
    if(setting || window.socket.readyState !== 1) return
    window.socket.send(JSON.stringify({action: 'ping'}))
}

function messageReceived(event) {
    // find the right callback according to type provided
    const response = JSON.parse(event.data)
    if(response === 'pong') return

    const callback = window.socketEvents[response.type]
    if(callback === undefined) return console.log('response type not recognised', response.type)
    callback(response)
}

export function addSocketListener(callback, key) {
    window.socketEvents[key] = callback
}

export function removeSocketListener(key) {
    if(window.socketEvents === undefined) return
    delete window.socketEvents[key]
}
